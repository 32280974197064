import projects from './projects';

export default function Home() {
  return (
    <div className="py-5 px-10 flex flex-col justify-evenly">
      <p className="text-2xl underline underline-offset-2">
        Et investeringsselskap med fokus på eiendom.
      </p>

      <div className="flex-grow my-10">
        <p className="text-xl">Våre prosjekter</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 mt-3 gap-4">
          {projects && projects.map((elem, i) => (
            <div key={i} className="hover:cursor-pointer" onClick={() => window.open(elem.link, '_blank')}>
              <img className="h-full w-full object-cover rounded" alt="Prosjektbilde" src={elem.img} />
              <p className="font-semibold text-center p-1">{elem.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <p className="text-2xl">Hvem er vi?</p>
        <div className="flex space-x-28 mt-2">
          <div className="flex flex-col space-y-2">
            <p className="text-md">Øystein Moskvil</p>
            <p>Daglig leder</p>
            <a className="underline underline-offset-2 decoration-levito hover:text-levito" href="tel:93443143">934 43 143</a>
          </div>
          <div className="flex flex-col space-y-2">
            <p>Frank Skoland</p>
            <p>Styrets leder</p>
            <a className="underline underline-offset-2 decoration-levito hover:text-levito" href="tel:93290381">932 90 381</a>
          </div>
        </div>
      </div>
    </div>
  );
}