
export default function Footer() {
  return (
    <nav>
      <div className="flex-initial h-24 p-5 pl-10 text-levito text-sm">
        <p className="text-xl font-bold">Levito AS</p>
        <p>Org: 996 830 543</p>
      </div>
    </nav>
  );
}