import { Logo } from './logo';

export default function Header() {
  return (
    <nav>
      <div className="relative h-14 flex m-10">
        <Logo />
      </div>
    </nav>
  );
}